<template>
    <b-modal id="fastAddressesModalEdit" title="Изменить данные быстрого адреса" size="sm" hide-footer no-enforce-focus
        :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <label>Адрес</label>
                        <b-input v-model="fastAddress.name" placeholder="Быстрый адрес" />
                    </b-col>
                    <b-col cols="12">
                        <label>Улица</label>
                        <v-select v-model="fastAddress.street_id" placeholder="Улица" label="name"
                            :reduce="street => street.id" :options="streets" class="select-size-md" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <label>Категория</label>
                        <v-select v-model="fastAddress.category_id" :reduce="categorie => categorie.id"
                            placeholder="Категория" label="name" :options="categories" class="select-size-md" />
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="4">
                        <label>Долгота</label>
                        <b-input v-model="fastAddress.lng" placeholder="Долгота" />
                    </b-col>
                    <b-col cols="4">
                        <label>Широта</label>
                        <b-input v-model="fastAddress.lat" placeholder="Широта" />
                    </b-col>
                    <b-col cols="4">
                        <label>Номер дома</label>
                        <v-select v-model="fastAddress.home_number_id" :reduce="hom_number => hom_number.id"
                            placeholder="Hомер дома" label="name" :options="home_numbers" class="select-size-md" />
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <!-- <b-col cols="6">
                        <label>Место встречи</label>
                        <b-input v-model="fastAddress.meeting_points" placeholder="Место встречи" />
                    </b-col> -->
                    <b-col cols="12">
                        <label>Главный вход</label>
                        <b-input v-model="fastAddress.main_entrance" placeholder="Главный вход" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cals="4" class="mt-2">
                        <b-button variant="primary" v-b-modal.modalSynonymsEdit @click="sendSynonyms()">
                            Cинонимы({{ fastAddress.synonyms.length }})
                        </b-button>
                    </b-col>
                    <b-col>
                        <b-form-checkbox class="mt-2" v-model="fastAddress.is_active" checked="true" name="check-button" switch inline>
                            {{this.fastAddress.is_active ? 'Активный' : ' Не активный'}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                        <b-button :disabled="submitButtonDisabled" class="mt-2 col-md-5" variant="primary"
                            @click="createFastAdresses()">
                            Добавить
                        </b-button>
                        <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                            Отмена
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <map-vue @latLng="latLng" />
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mapVue from '../../Map/map.vue'

export default {
    components: {
        vSelect,
        ToastificationContent,
        mapVue,
    },
    props: ['id', 'changesSynonyms'],
    data() {
        return {
            fastAddress: {
                name: '',
                category_id: '',
                home_number_id: '',
                street_id: '',
                lat: '',
                lng: '',
                synonyms: '',
                meeting_points: undefined,
                main_entrance: '',
                is_active: ''
            },
            street_select: '',
            streets: [],
            categories: [],
            home_numbers: [],
            editSynonyms: [],
            submitButtonDisabled: false,
        }
    },
    methods: {
        createFastAdresses() {
            this.fastAddress.synonyms = JSON.stringify(this.changesSynonyms)
            this.fastAddress.meeting_points = undefined
            if (this.fastAddress.is_active == true) {
                this.fastAddress.is_active = 1
            } else {
                this.fastAddress.is_active = 0
            }
            this.submitButtonDisabled = true
            this.$http
                .patch(`addresses/fast/addresses/${this.id.id}`, this.fastAddress)
                .then(res => {
                    this.btnCancel()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Быстрый адрес успешно изменен!`,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
        },
        opened() {
            let syn
            this.$http
                .get(`addresses/fast/addresses/${this.id.id}/edit`)
                .then(res => {
                    this.fastAddress = res.data
                    if (this.fastAddress.is_active == 1) {
                        this.fastAddress.is_active = true
                    } else {
                        this.fastAddress.is_active = false
                    }
                    syn = res.data.synonyms
                    syn.forEach(el => {
                        this.editSynonyms.push({ name: el.name })
                    })
                })
            this.$http
                .get('addresses/streets').then(res => {
                    this.streets = res.data
                })
            this.cleardata()
            this.$http
                .get('addresses/fast/addresses/data')
                .then(res => {
                    this.categories = res.data.categories
                    this.home_numbers = res.data.home_numbers
                })
                .catch(err => {
                    console.log(err);
                })
        },
        latLng(latLng) {
            this.fastAddress.lat = latLng.lat
            this.fastAddress.lng = latLng.lng
        },
        btnCancel() {
            this.$bvModal.hide('fastAddressesModalEdit')
            this.cleardata()
        },
        cleardata() {
            this.fastAddress = {
                name: '',
                category_id: '',
                home_number_id: '',
                street_id: '',
                lat: '',
                lng: '',
                synonyms: '',
                meeting_points: '',
                main_entrance: ''
            },
                this.street_select = '',
                this.streets = [],
                this.categories = [],
                this.hom_numbers = [],
                this.editSynonyms = []
            this.street_select = undefined
            // this.streets = [],
        },
        sendSynonyms() {
            this.$emit("sendSynonyms", this.editSynonyms)
        }
    }
}
</script>