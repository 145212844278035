<template>
    
    <b-modal dialog-class="my-modal" :id="$route.name + 'CreateModal'" title="Добавить быстрый адрес" size="lg" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Адрес</label>
                        <b-input v-model="fastAddresses.name" placeholder="Быстрый адрес" />
                    </b-col>
                    <b-col cols="6">
                        <label>Улица</label>
                        <v-select
                            v-model="fastAddresses.street_id"
                            @input="setSelected"
                            :reduce="option => option.id"
                            placeholder="Улица"
                            label="name"
                            :options="streets"
                            class="select-size-md"
                        />
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="6">
                        <label>Категория</label>
                        <v-select
                            v-model="fastAddresses.category_id"
                            :reduce="categorie => categorie.id"
                            placeholder="Категория"
                            label="name"
                            :options="categories"
                            class="select-size-md"
                        />
                    </b-col>
                    <b-col cols="6">
                        <label>Номер дома</label>
                        <v-select
                            v-model="fastAddresses.home_number_id"
                            :reduce="hom_number => hom_number.id"
                            placeholder="Hомер дома"
                            label="name"
                            :options="hom_numbers"
                            class="select-size-md"
                        />
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="6">
                        <label>Долгота</label>
                        <b-input v-model="fastAddresses.lng" placeholder="Долгота" />
                    </b-col>
                    <b-col cols="6">
                        <label>Широта</label>
                        <b-input v-model="fastAddresses.lat" placeholder="Широта" />
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="6">
                        <label>Главный вход</label>
                        <b-input v-model="fastAddresses.main_entrance" placeholder="Главный вход" />
                    </b-col>
                    <b-col cols="6">
                        <b-button variant="primary" v-b-modal.modalSynonyms>
                            Добавить синонимы
                        </b-button>
                    </b-col>  
                </b-row>
                <b-row> 
                    <b-col cols="12" class="d-flex justify-content-between">
                        <b-button
                        :disabled="submitButtonDisabled"
                        class="mt-2 col-md-5"
                        variant="primary"
                        @click="createFastAdresses()">
                            Добавить
                        </b-button>
                        <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                            Отмена
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <map-vue @latLng="latLng" />
    </b-modal>

</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mapVue from '../../Map/map.vue'

export default {
    components: {
        vSelect,
        ToastificationContent,
        mapVue,
    },
    props: ['synonyms'],
    data(){
        return {
            fastAddresses: {
                name: '',
                category_id: '',
                home_number_id: '',
                street_id: '',
                lat: '',
                lng: '',
                synonyms: '',
                meeting_points: undefined,
                main_entrance: ''
            },
            street_select: undefined,
            streets: [],
            categories: [],
            hom_numbers: [],
            submitButtonDisabled: false,
        }
    },
    methods: {
        createFastAdresses(){
            this.fastAddresses.synonyms = JSON.stringify(this.synonyms)
            this.submitButtonDisabled = true
            this.$http
                .post('addresses/fast/addresses', this.fastAddresses)
                .then(res => {
                    this.btnCancel()
                    // this.$bvModal.hide('fastAddressesModal')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Быстрый адрес успешно добавлена!`,
                        },
                    })
                    this.$emit('refresh')
                    this.btnCancel()
                    // this.$bvModal.hide(this.$route.name + 'CreateModal')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get('addresses/streets').then(res => {
                    this.streets = res.data
                })
            this.cleardata()
            this.$http
            .get('addresses/fast/addresses/data')
            .then(res =>{
                this.categories = res.data.categories
                this.hom_numbers = res.data.home_numbers
            })
            .catch(err=>{
                console.log(err);
            })
        },
        setSelected(){
            this.$http
            .get(`addresses/fast/addresses/data?street_id=${this.fastAddresses.street_id}`)
            .then(res =>{
                this.categories = res.data.categories
                this.hom_numbers = res.data.home_numbers
            })
        },
        latLng(latLng){
            this.fastAddresses.lat = latLng.lat
            this.fastAddresses.lng = latLng.lng
        },
        btnCancel(){
        this.$bvModal.hide(this.$route.name + 'CreateModal')
        this.cleardata()
        },
        cleardata(){
            this.fastAddresses = {
                name: '',
                street_id: '',
                lat: '',
                lng: '',
            },
            this.street_select = undefined
            // this.streets = [],
        }
    }
}
</script>

<style>
.modal {
   
}
</style>
