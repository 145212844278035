<template>
  <b-modal id="modalSynonymsEdit"  title="Синонимы" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true">
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col>
            <div  v-for="(synonym, index) in editSynonyms" :key="index" class="d-flex ">
              <b-col cols="10">
                <b-input  v-model="synonym.name" class="mt-2"/>
              </b-col>
              <b-col cols="2">
                <b-button @click="removeSynonyms(index)" variant="dark" class="mt-2">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; justify-content: space-between;" class="mx-1 mt-2">
          <b-button @click="createNewSynonym()" variant="outline-primary btn-icon">
            <feather-icon icon="PlusIcon" />
          </b-button>
          <b-button variant="primary" @click="changeSynonyms()">
            Изменить
          </b-button>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: ['editSynonyms'],
  data() {
    return {
      changesSynonyms: []
    }
  },
  methods: {
    createNewSynonym(){
      this.editSynonyms.push({name: ''})
    },
    removeSynonyms(index){
      this.editSynonyms.splice(index, 1)
    },
    changeSynonyms(){
      this.changesSynonyms = this.editSynonyms
      this.$bvModal.hide('modalSynonymsEdit')
      this.$emit("changeSynonyms", this.changesSynonyms)
      this.editSynonyms =[
        {name: ''}
      ]
    }
  }
}
</script>