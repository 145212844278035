<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="my-card m-0">
                <b-table 
                striped
                small
                id="myTables"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                :items="fastAddresses"
                :fields="fields"
                @row-dblclicked="editForm"
                @row-clicked="clicked"
                head-row-variant="secondary"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template>
                <template #cell(synonyms)="data">
                    <span
                    v-for="synonym in data.item.synonyms"
                    :key="'synonym-' + synonym.id">
                        {{ synonym.name }};
                    </span>
                </template>  
                <template #cell(is_active)="data">
                    {{ data.item.is_active == 1 ? 'Активен' : 'Неактивен' }}
                </template>
            </b-table>
        </b-card>
    </b-col>
    </b-row>
</div>
    
</template>
<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
    props: ['fastAddresses'],
    data(){
        return {
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                { key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '150px'}},
                { key: 'category.name', label: 'Категория', sortable: true, thStyle: {width: '150px'}},
                { key: 'street', label: 'Улица', sortable: true, thStyle: {width: '150px'}},
                { key: 'street_type', label: 'Тип улицы', sortable: true, thStyle: {width: '150px'}},
                { key: 'home_number.name', label: '№ дома', sortable: true, thStyle: {width: '150px'}},
                { key: 'district.name', label: 'Район', sortable: true, thStyle: {width: '150px'}},
                { key: 'village', label: 'Населённый пункт', sortable: true, thStyle: {width: '150px'}},
                { key: 'city', label: 'Город', sortable: true, thStyle: {width: '150px'}},
                { key: 'region', label: 'Регион', sortable: true, thStyle: {width: '150px'}},
                { key: 'synonyms', label: 'Синонимы', sortable: true, thStyle: {width: '150px'}},
                { key: 'is_active', label: 'Cтатус', sortable: true, thStyle: {width: '150px'}},
                { key: 'lng', label: 'Долгота', sortable: true, thStyle: {width: '150px'}},
                { key: 'lat', label: 'Широта', sortable: true, thStyle: {width: '150px'}},
            ],
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        editForm(id){
            this.$emit('editForm', id)
        },
        refresh(){
            this.$emit('refresh')
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key; 
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }  
.my-danger{
    background-color: #f9cfcf;
}  
.my-success{
    background-color: #8fe2b4;
} .my-secondary{
    background-color: #dcdddf;
} .my-warning{
    background-color: #ffcd9d;
}   
</style> 